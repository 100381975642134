import Vue from "vue";
import display from "@/configure/display/operator/operator";
import {OperatorEntity, OperatorQuery} from "@/core/entity/core/operator";
import {DataOptions, DataTableHeader} from "vuetify";
import service from "@/core/service";
import tip from "@/configure/tip";

export default Vue.extend({
    data: () => ({
        show: true,
        loading: false,
        headers: <DataTableHeader[]>[
            {text: display.name, value: 'name',width:80},
            {text: display.account, value: 'account',width:80},
            {text: display.phone, value: 'phone',width:80},
            {text: display.mail, value: 'mail'},
            {text: display.createTime, value: 'createTime'},
            {text: display.operOperatorName, value: 'operOperatorName',width:120},
            {text: display.operTime, value: 'operTime'},
            {text: display.status, value: 'status',width:100},
            {
                text: '',
                value: 'action',
                cellClass: ["d-flex", "justify-space-around", "align-center"],
                sortable: false,
                width: 140,
                align: 'center'
            },
        ],
        options: <DataOptions><unknown>{sortBy: [], sortDesc: [], page: 1, itemsPerPage: 10},
        data: <OperatorQuery>{},
        result: <OperatorEntity[]>[],
        resultLength: 0,
        datetime: "",
        display
    }),
    methods: {
        async query() {
            this.loading = true;
            try {
                const result = await service.operator.query(this.$queryData(this.data, this.options, ["name","account"]));
                this.result = result;
                this.resultLength = result.dataLength!;
            } finally {
                this.loading = false;
            }
        },
        async add() {
            await this.$router.push({path: "/operator/add"});
        },
        async load(key: number) {
            await this.$router.push({path: "/operator/load/" + key});
        },
        async edit(key: number) {
            await this.$router.push({path: "/operator/edit/" + key});
        },
        async del(key: number) {
            if (await this.$confirm(tip.confirm.del)) {
                await service.port.del(key);
                await this.query();
            }
        },
    }
});
