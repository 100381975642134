export default {
    account: "账号",
    name: "名称",
    phone: "电话",
    mail: "邮箱",
    status: "是否有效",
    createTime: "创建时间",
    operTime: "最后修改时间",
    operOperatorName: "最后修改人",
    operTimeFrom : "修改时间开始",
    operTimeTo: "修改时间结束",
}
