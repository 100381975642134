import {UmEntity, UmQuery} from "../core";



/**
 * 业务用户(Entity)
 * @author syj
 * @date 2021-04-22 14:36
 */
export class OperatorEntity extends UmEntity {
	//账号
	public account?: string;
	//密码(需加密)
	public password?: string;
	//名称
	public name?: string;
	//
	public phone?: string;
	//
	public mail?: string;
	//是否有效
	public status?: number;
}


/**
 * 业务用户(Query)
 * @author syj
 * @date 2021-04-22 14:36
 */
export class OperatorQuery extends UmQuery {

	//账号
	public account?: string;
	//名称
	public name?: string;
	//oper time从
	public operTimeFrom ?: number

	//oper time至
	public operTimeTo ?: number
}
